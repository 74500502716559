/* eslint-disable react/no-unescaped-entities, no-console */

import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useKeycloak } from '@react-keycloak/web';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { formatPhoneInput } from '../utils/inputs';
import '../styles/index.css';
import AllHealthIcon from '../svgs/all-health-black.svg';
import { isBrowser } from '../services/keycloak';

const Layout = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const Container = styled(Box)`
  box-sizing: border-box;
  width: 432px;
  margin: 0;
  padding: 72px 48px 72px 48px;
  position: relative;
  background: #f7f7f7;
  border-radius: 12px;
  padding-bottom: 72px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const HeaderTitle = styled.div`
  width: 100%;
  margin: 51px 0 36px 0;
  text-align: center;
  font-family: Gotham SSm;
  font-size: 19px;
  color: rgb(80, 86, 97);
  font-weight: 700;
  line-height: 1.26316;
`;

const InfoText = styled.span`
  display: block;
  margin-bottom: 36px;
  font-family: Gotham SSm;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(80, 86, 97, 0.8);
  text-align: center;
`;

const SubInfoText = styled.span`
  display: block;
  margin-top: 36px;
  padding-top: 16px;
  font-family: Gotham SSm;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(80, 86, 97, 0.5);
  border-top: 1px solid #DEDEDE;
`;

const InputLabel = styled.span`
  margin: 0px;
  font-family: Gotham SSm;
  color: rgb(80, 86, 97);
  font-size: 11px;
  font-weight: 700;
  line-height: 1.63636;
`;

const Input = styled(OutlinedInput)`
  && {
    background-color: white;
    margin-bottom: 18px;
    height: 50px;
    font-size: 12px;
    font-family: "Gotham SSm", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 500;
    color: rgb(80, 86, 97);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(21, 188, 207) !important;
    border-width: 2px;
  }

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const ErrorAlert = styled(Alert)`
  && {
    margin-bottom: 16px;
    font-family: Gotham SSm;
    font-size: 12px;
    font-weight: 500;
    color: rgb(80, 86, 97);
  }
`;

const SendButton = styled(Button)`
  && {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: "Gotham SSm", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.5;
    text-transform: none;
    box-shadow: rgba(80, 86, 97, 0.2) 0px 15px 18px -11px;
    border-radius: 3px;
    padding: 9px 18px;
    transition: none 0s ease 0s;
    position: relative;
    z-index: 0;
    color: rgb(255, 255, 255);
    background: rgb(80, 86, 97);
    width: 100%;
    height: 48px;
    margin-top: 36px;

    &:hover {
      background: rgb(80, 86, 97);
    }
  }

  &.Mui-disabled {
    background: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
  }
`;

export default () => {
  const { keycloak } = useKeycloak();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  if (isBrowser() && !keycloak.authenticated) {
    const selectedRealm = localStorage.getItem('realm');
    if (selectedRealm) {
      const clientId = localStorage.getItem('clientId');
      if (clientId === 'website-email') {
        const userEmail = localStorage.getItem('userEmail');
        keycloak.login({
          redirectUri: `${window.location.origin}/`,
          loginHint: userEmail,
        });
      } else {
        const userId = localStorage.getItem('userId');
        const loginUrl = `${keycloak.createLoginUrl()}&user_id=${userId}`;
        window.location.href = loginUrl;
      }
      return null;
    }
  }

  if (isBrowser() && keycloak.authenticated) {
    navigate('/');
    return null;
  }

  const handleEmailChange = (event) => {
    setError('');
    const newValue = event?.currentTarget?.value || '';
    setEmail(newValue);
  };

  const handlePhoneChange = (event) => {
    setError('');
    const formattedPhone = formatPhoneInput(event?.currentTarget?.value || '');
    setPhone(formattedPhone);
  };

  // to test locally run netlify functions:serve and change url to http://localhost:9999/
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email || phone) {
      try {
        setIsLoading(true);
        const response = await fetch('/.netlify/functions/find-user', {
          method: 'POST',
          body: JSON.stringify({
            ...email && {
              email
            },
            ...phone && {
              phoneNumber: phone
            }
          })
        });
        setIsLoading(false);
        if (response.status === 404) {
          setError('User not found. Please try another one');
        } else if (!response.ok) {
          setError('Something went wrong! Please try again');
        } else {
          const data = await response.json();
          const tenant = data?.userGroups?.[0]?.tenantName;
          if (phone) {
            localStorage.setItem('userId', data?.user?.id || '');
            localStorage.setItem('clientId', 'website-phone');
            localStorage.setItem('realm', data?.realm || 'care');
            localStorage.setItem('tenant', tenant || '');
          } else {
            localStorage.setItem('userId', data?.user?.id || '');
            localStorage.setItem('userEmail', email);
            localStorage.setItem('clientId', 'website-email');
            localStorage.setItem('realm', data?.realm || 'care');
            localStorage.setItem('tenant', tenant || '');
          }
          window.location.reload();
        }
      } catch (e) {
        setIsLoading(false);
        setError('Something went wrong! Please try again');
        console.error('Error fetching data:', e);
      }
    }
  };

  return !keycloak.authenticated && isBrowser() && !localStorage.getItem('realm') ? (
    <>
      <Helmet>
        <title>all.health sign</title>
        <meta
          name="description"
          content="We detect lifestyle diseases early and help members manage existing conditons to influence health outcomes. Sign up to learn more."
        />
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <AllHealthIcon width={60} height={43.01} />
            <HeaderTitle>all.health sign in</HeaderTitle>
          </Header>
          <Box>
            {error && (
              <ErrorAlert severity="error">
                {error}
              </ErrorAlert>
            )}
            <InfoText>
              Please enter your email address
              {' '}
              <b>OR</b>
              {' '}
              phone number to sign in
            </InfoText>
            <form id="realm-form" onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <InputLabel>Email Address</InputLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  notched={false}
                  onChange={handleEmailChange}
                  autoComplete="email"
                  inputProps={{ autoComplete: 'email' }}
                  disabled={!!phone}
                  style={{ marginBottom: '8px' }}
                />
              </FormControl>
              <InputLabel>OR</InputLabel>
              <FormControl fullWidth style={{ marginTop: '6px' }}>
                <InputLabel>Phone Number</InputLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  notched={false}
                  value={phone}
                  onChange={handlePhoneChange}
                  autoComplete="tel"
                  inputProps={{ autoComplete: 'tel' }}
                  disabled={!!email}
                />
              </FormControl>
              <div>
                <SendButton
                  name="login"
                  type="submit"
                  disableRipple
                  disabled={(!email && !phone) || isLoading}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    'Submit'
                  )}
                </SendButton>
              </div>
              <SubInfoText>
                Note: this login provides access to support information for
                active members and more info on all.health. To access your
                all.health program, download the all.health app on your phone
                and log in to your account there.
              </SubInfoText>
            </form>
          </Box>
        </Container>
      </Layout>
    </>
  ) : (
    <Helmet>
      <title>all.health sign</title>
      <meta
        name="description"
        content="We detect lifestyle diseases early and help members manage existing conditons to influence health outcomes. Sign up to learn more."
      />
    </Helmet>
  );
};
