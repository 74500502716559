import { AsYouType } from 'libphonenumber-js';

export const formatPhoneInput = (value = '') => {
  const parser = new AsYouType('US');
  parser.input(value);
  const phoneNumber = parser.getNumber();
  if (!phoneNumber) {
    return value;
  }
  return phoneNumber.formatInternational();
};
